<template>
    <div class="opcoes-do-menu">
        <ul>
            <li v-for="categoria in categorias" :key="categoria.codigo">
                <router-link :to="'/'+$route.params.url_amigavel+ '/#category_anchor_' + categoria.nome">
                    {{categoria.nome}}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    // importa o store
	import store from '@/store'

    export default {
		name: 'CategoriasHomeCardapio',
        components: {
        },
        data: function () {
            return {
                categorias: [
                ],
            }
        },

		// funções que rodam quando o componente é montado
		async mounted(){
            // // faz a requisição para o back para coletar os grupos
            var resp = await store.dispatch('getCardapio', {codigo_empresa_servico:store.getters.empresa_cardapio.codigo_empresa_servico,codigo_empresa_id:store.getters.empresa_cardapio.codigo_empresa_id,tipo:'categorias'});
            if(resp.dados.lenght > 0){
                this.categorias = resp.dados
            }else{
                this.categorias = resp.dados
            }
		}
    }
</script>