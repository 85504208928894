<template>
    <a :href="href" target="_blank">
        <figure id="logo-facebook">
            <img class="img-responsive" alt="LogoFacebook" src="@/assets/images/logo-facebook.png">
        </figure>
    </a>
</template>

<script>
	export default {
		name: 'LogoFacebook',
		data: () => ({
        }),
        props: {
            href:[String]
        },
	}
</script>

<style lang="scss">
	#logo{
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 250px;
		margin-bottom: 10px;
		margin-top: 10px;
		img{
			width: 100%;
		}
	}
</style>