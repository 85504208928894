<template>
    <div id="homeCardapio">
        <!-- VERSÃO MOBILE -->
        <div v-if="$store.getters.screen_size.width <= 767" class="wrapper conteudo">
            <div class="row">
                <div class="col-12 col-conteudo">
                    <div class="div-menu-lateral" v-bind:class="{ open: abreMenu == true }">
                        <MenuLateral @estadoMenuLateral="estadoMenuLateral"/>
                    </div>
                    <div class="div-botoes">
                        <div class="btn-menu" @click="estadoMenuLateral(true)">
                            <v-icon color="#FE8204">mdi-menu</v-icon>
                        </div>
                        <div class="btn-voltar">
                            <router-link :to="'/' + this.$route.params.url_amigavel + '/login'" v-if="$store.getters.isLoggedIn">
                                <v-icon color="#FE8204">mdi-chevron-left</v-icon>
                            </router-link>
                        </div>
                    </div>
                    <div class="logo-empresa">
                        <LogoEmpresas :logo="LogoEmpresa"/>
                    </div>
                    <div class="bem-vindo">
                        <p>Bem-vindo (a)</p>
                        <p class="selecione">Selecione uma das opções:</p>
                    </div>
                    <CategoriasHomeCardapio/>

                    <div v-if="link_facebook != null || link_instagram != null || link_twitter != null" class="col-6 offset-3 redes-sociais">
                        <div class="texto">
                            <p><span>Siga-nos</span> nas redes sociais</p>
                        </div>
                        <div class="row row-midias">
                            <div class="midias">
                                <div v-if="link_facebook != null" class="div-facebook">
                                    <LogoFacebook
                                        :href="link_facebook"
                                    />
                                </div>
                                <div v-if="link_instagram != null" class="div-instagram">
                                    <LogoInstagram
                                        :href="link_instagram"
                                    />
                                </div>
                                <div v-if="link_twitter != null" class="div-twitter">
                                    <LogoTwitter
                                        :href="link_twitter"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rodape">
                        <div class="row row-rodape">
                            <div class="btn-meus-pedidos">                                
                                <router-link :to="'/' + this.$route.params.url_amigavel + '/historico-de-pedidos'" v-if="$store.getters.isLoggedIn">
                                    <figure id="logo-meus-pedidos">
                                        <img class="img-responsive" alt="Meus pedidos" src="@/assets/images/meus-pedidos.png">
                                    </figure>
                                    <span>Meus pedidos</span>
                                </router-link>
                            </div>
                            <div class="btn-minha-conta">
                                <router-link :to="'/' + this.$route.params.url_amigavel + '/perfil'" v-if="$store.getters.isLoggedIn">
                                    <figure id="logo-minha-conta">
                                        <img class="img-responsive" alt="Minha conta" src="@/assets/images/minha-conta.png">
                                    </figure>
                                    <span>Minha conta</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- VERSÃO WEB -->
        <div v-else class="container conteudo">
            <div class="row">
                <div class="col-12">
                    <div class="logo-empresa">
                        <LogoEmpresas :logo="LogoEmpresa"/>
                    </div>
                    <CategoriasHomeCardapio/>
                </div>
            </div>
            <div v-if="link_facebook != null || link_instagram != null || link_twitter != null" class="row">
                <div class="col-6 offset-3 redes-sociais">
                    <div class="texto">
                        <p>Siga-nos nas redes<br>sociais</p>
                    </div>
                    <div class="row row-midias">
                        <div class="midias">
                            <div v-if="link_facebook != null" class="div-facebook">
                                <LogoFacebook
                                    :href="link_facebook"
                                />
                            </div>
                            <div v-if="link_instagram != null" class="div-instagram">
                                <LogoInstagram
                                    :href="link_instagram"
                                />
                            </div>
                            <div v-if="link_twitter != null" class="div-twitter">
                                <LogoTwitter
                                    :href="link_twitter"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper-logoFinerFoods">
            <LogoFinerFoods/>
        </div>
    </div>
</template>

<script>
	// importa o store
	import store from '@/store'

    import MenuLateral from '@/components/MenuLateral.vue'
    import BG from '@/assets/images/BG.png'
    import LogoFinerFoods from '@/components/LogoFinerFoods.vue'
    import LogoEmpresas from '@/components/LogoEmpresas.vue'
    import CategoriasHomeCardapio from '@/components/CategoriasHomeCardapio.vue'
    import LogoFacebook from '@/components/LogoFacebook.vue'
    import LogoInstagram from '@/components/LogoInstagram.vue'
    import LogoTwitter from '@/components/LogoTwitter.vue'

    export default {
        name: 'HomeCardapio',
        components: {
            MenuLateral,
            LogoFinerFoods,
            LogoEmpresas,
            CategoriasHomeCardapio,
            LogoFacebook,
            LogoInstagram,
            LogoTwitter
        },
        data: function () {
            return {
                link_facebook: null,
                link_instagram: null,
                link_twitter: null,
                background: BG,
                LogoEmpresa: '',
                abreMenu: ''
            }
        },
        methods: {
            estadoMenuLateral(value){
                this.abreMenu = value
            }
        },
        computed: {
        },
		// funções que rodam quando o componente é montado
		async mounted(){
            this.LogoEmpresa = store.getters.empresa_cardapio.imagem_logo
            // this.link_facebook = localStorage.getItem('link_facebook')
            // this.link_instagram = localStorage.getItem('link_instagram')
            // this.link_twitter = localStorage.getItem('link_twitter')
            this.link_facebook = 'https://finer.com.br'
            this.link_instagram = 'https://finer.com.br'
            this.link_twitter = 'https://finer.com.br'
		}
    }
</script>

<style lang="scss">
    #homeCardapio{
        min-height: 100vh;
        position: relative;
        overflow-x: hidden;
        padding: 75px 0;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-position: center;
            width: 100%;
            padding-top: 100px;
            z-index: 1;
            background-repeat: no-repeat;
            background-image: url('~@/assets/images/BG.png');
            @media (max-width: 767px){
                display: none;
            }
        }
        .conteudo{
            z-index: 2;
            position: relative;
            .logo-empresa{
				transform: translateY(-50px);
                width: 150px;
                height: 150px;
                margin: auto;
                position: relative;
                margin-bottom: -50px;
                @media (max-width: 767px) {
                    transform: initial;
                    margin-bottom: 0;
                }
            }
            .col-conteudo{
                padding: 0;
                .div-menu-lateral{
                    position: fixed;
                    top: 0;
                    transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                    width: 60px;
                    right: 100%;
                    max-width: 100vw;
                    z-index: 100;
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
                    max-height: 100vh;
                    .background{
                        display: block;
                        background-color: #33333333;
                        content: "";
                        backdrop-filter: blur(7px);
                        position: absolute;
                        left: 100%;
                        top: 0;
                        visibility: hidden;
                        opacity: 0;
                        width: 100vw;
                        height: 100vh;
                    }
                    .wrapper-btn-minimize{
                        display: flex;
                        justify-content: flex-end;
                        .btn-minimize{
                            border: none;
                            background-color: transparent;
                            align-self: flex-end;
                            transform: rotate(-180deg);
                            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                            height: 52px;
                        }
                    }
                    &.open{
                        width: 100vw;
                        z-index: 10;
                        transform: translateX(100%);
                        background-color: #fff;
                        .btn-minimize{
                            transform: rotate(0deg);
                        }
                        .background{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
            .div-botoes{
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                @media (max-width: 767px) {
                    padding: 12px;
                }
                .btn-voltar{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    width: max-content;
                    border: 1px solid #FE8204;
                    width: 30px;
                    height: 30px;
                }
                .btn-menu{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
            }
            .bem-vindo{
                p{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    color: #404040;
                    margin: 0;
                    &.selecione{
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
            .opcoes-do-menu{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                padding-top: 50px;
                ul{
                    list-style: none;
                    padding: 0;
                    li{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        padding-bottom: 26px;
                        a{
                            background: #FFFFFF;
                            border: 2px solid #FE8204;
                            box-sizing: border-box;
                            box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
                            border-radius: 90px;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: normal;
                            font-size: 28px;
                            line-height: 42px;
                            text-align: center;
                            color: #FE8204;
                            padding: 25px;
                            min-width: 370px;
                            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                            &:hover{
                                background-color: #FE8204;
                                color: #FFFFFF;
                            }
                        }
                    }
                    @media (max-width: 767px){
                        width: 100%;
                        li{
                            padding-bottom: 0;
                            a{
                                width: 100%;
                                border-radius: 0;
                                border: none;
                                border-top: 1px solid #FE8204;
                                font-size: 20px;
                                line-height: 30px;
                            }
                            &:nth-last-child(1){
                                border-bottom: 1px solid #FE8204;
                            }
                        }
                    }
                }
                @media (max-width: 767px){
                    padding-top: 30px;
                }
            }
            .redes-sociais{
                margin-top: 30px;
                font-family: 'Poppins';
                .texto{
                    text-align: center;
                    font-weight: 400;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-size: 24px;
                    line-height: 36px;
                    text-align: center;
                    color: #4F4F4F;             
                    @media (max-width: 767px) {
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        color: #FE8204;
                    }       
                }
                .row-midias{
                    justify-content: center;
                    .midias{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        img{
                            width: 60px;
                            padding: 0 10px;
                        }
                    }
                }
            }
            .rodape{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: #262626;
                margin-top: 30px;
                padding: 40px 80px;
                .row-rodape{
                    display: flex;
                    justify-content: space-evenly;
                    align-items: baseline;
                    figure{
                        display: flex;
                        justify-content: center;
                        align-items: center;         
                    }
                    .btn-meus-pedidos, .btn-restaurantes, .btn-minha-conta{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        a{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-family: 'Josefin Sans';
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 12px;
                            text-align: center;
                            color: #FFFFFF;
                            span{
                                margin-top: 10px;
                            }
                        }
                    }
                }
                @media (max-width: 450px){
                    padding: 30px 0px;
                    margin-top: 0;
                }
            }
        }
        .wrapper-logoFinerFoods{
            position: fixed;
            bottom: 5%;
            right: 5%;
            @media (max-width: 767px){
                display: none;
            }
        }
        @media (max-width: 767px){
            padding: 0 12px;
        }
    }
</style>